<template>
  <main>
    <link rel="website icon" type="png" href="/front-end/public/vigorini.png">
    <div class="headline">
      <img class="logo" src="/vigorini.png" alt="">
      <div class="user">Ciao {{ username }}</div>
    </div>

    <div class="container">
      <riquadriVue 
      v-for="(item, index) in sets"
      :key="index"
      :imageUrl="item.imageUrl"
      :Nome="item.Nome"
      :href="item.href"
      /> 
    </div>

    <!--<p>Made with <svg-icon type="mdi" class="heart" :path="path"></svg-icon> by Viscom Web</p>-->

  </main>
</template>

<script>
import { ref, onMounted } from 'vue';
import KeycloakService from './security/KeycloakService';
import axios from 'axios';
import riquadriVue from './views/riquadriVue.vue';
//import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCardsHeartOutline } from '@mdi/js';

export default {
  components: { riquadriVue},
  name: 'App',
  name2: 'my-component',
  setup() {
    const username = ref('');
    const sets = ref([]);

    onMounted(() => {
      const keycloak = KeycloakService.Instance;
      if (keycloak && keycloak.tokenParsed) {
        username.value = keycloak.tokenParsed.given_name;
      }

       // Caricare i dati dal file JSON
       axios.get('/fileVigorini.json')
        .then(response => {
          sets.value = response.data;
        })
        .catch(error => {
          console.error('Error loading JSON data:', error);
        });
    });

    return {
      username,
      sets,
      path: mdiCardsHeartOutline,
    };
  },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #d3ebf8;
  font-family: 'Montserrat', sans-serif;
}

main {
  height: 90vh;
  display: flex;
  display: flex;
  flex-direction: column;
}

/* --------------------TESTATA --------------------*/
.headline {
  border-bottom: 1px solid rgb(7, 0, 0);
  background-color: #AFD7ED;
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  box-sizing: border-box;
  height: 100%;
  width: 18vh;
  object-fit: contain;
  padding: 0px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25vh;
  font-size: large;
  font-size: 150%;
  margin-right: 5px;
}

/* --------------------ARGOMENTI GRIGLIA --------------------*/
.container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2px;
  justify-content: center;
  gap: 30px;
  padding-top: 40px;
}

p{
  text-align: center;
  padding-top: 25px;
  font-size: 12px;
}

.heart {
  width: 20px;
}
</style>  
