// main.js
import { createApp } from 'vue';
import App from './App.vue';
import KeyCloakService from './security/KeycloakService'; // Assumi che KeycloakService.js si trovi nella cartella 'security'

const renderApp = () => {
  const app = createApp(App);
  app.provide('keycloak', KeyCloakService.Instance);
  app.mount('#app');
};

KeyCloakService.CallLogin(renderApp);
