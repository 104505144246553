// KeycloakService.js
import Keycloak from 'keycloak-js';

const keycloakInstance = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param {Function} onAuthenticatedCallback - The callback function to execute if authentication is successful.
 */
const Login = (onAuthenticatedCallback) => {
  keycloakInstance
    .init({ onLoad: 'login-required' })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback(); 
      } else{
        alert('Not authenticated')
      }
    })
    .catch((e) => {
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};


const getUsername = () => {
  return keycloakInstance.tokenParsed ? keycloakInstance.tokenParsed.preferred_username : null;
};

const KeyCloakService = {
  CallLogin: Login,
  GetUsername: getUsername,
  Instance: keycloakInstance
};

export default KeyCloakService;
