<script>
export default {
    props: {
        href: {
            typeof: String,
            required: true
        },
        Nome: {
            typeof: String,
            required: true
        },
        imageUrl: {
            typeof: String,
            required: true
        },
    }
}
</script>

<template>
    
    <a :href="href" class="contenitore" target="blank">
        <img :src="imageUrl" alt="" class="immagine">
        <h3>{{ Nome }}</h3>
    </a>
    
</template>

<style>
.contenitore {
    background-color: #59baee;
    width: 40vh;
    height: 25vh;
    margin: 2px;
    padding: 26px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
}

.contenitore:hover {
    box-shadow: 0 29px 52px rgba(0,0,0,0.40), 0 25px 16px rgba(0,0,0,0.20);
    transform: scale(1.1); /* Aumenta la dimensione del pulsante del 10% */
}

.immagine {
    width: 10vh;
    height: 10vh;
    margin-bottom: 28px;
    object-fit: contain;
    opacity: 0.85;
}

h3 {
    margin: 0px;
    padding-left: 7px;
    color: rgba(255,255,255,0.85);
}

</style>